import classNames from 'classnames';
import {useFormik} from 'formik';
import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DialogContainer, {DialogButton} from '../../components/AlertDialog/DialogContainer';
import {
    AddNewWidgetDialogProps,
    defaultIFrameWidgetSize,
    defaultWidgetCallVolumesDataSize,
    defaultWidgetExtensionPresenseDataSize,
    useStyles,
    validationSchema
} from './AddNewWidgetDialog.utils';
import {Box, Grid} from '@material-ui/core';
import {
    BaseWallboardWidget,
    BaseWallboardWidgetViewProps,
    DefaultAutoRefreshPresetForDataType,
    ExternalIframeData,
    WidgetAutoRefreshPreset,
    WidgetCallVolumesData,
    WidgetCallVolumesTimeRange,
    WidgetDataType,
    WidgetExtensionPresenseData,
    WidgetExtensionPresenseFilter,
    WidgetPresantationType
} from '../../store/types/Wallboard';
import CustomizedTextField from '../../components/TextField/TextField';
import SelectField from '../../components/SelectField/SelectField';
import {DropDownDictionaryItem, GroupedDropDownDictionaryItem} from '../CallHistory/ActiveCalls/ActiveCalls.utils';
import {ReactComponent as DougnutWidgetType} from '../../assets/dougnut_widget_type.svg';
import {ReactComponent as ListWidgetType} from '../../assets/list_widget_type.svg';
import {ReduxState} from '../../store/types';
import {useSelector} from 'react-redux';
import CustomizedTooltip from '../../components/Tooltip/Tooltip';
import CustomizedChip from '../../components/Chip/Chip';
import CustomizedCheckbox from '../../components/Checkbox/Checkbox';
import {Delete} from '@material-ui/icons';
import {Colors} from '../../styles/Colors';

const AddNewWidgetDialog: React.FC<AddNewWidgetDialogProps> = ({
    isOpen,
    onClose,
    editObject,
    onDelete
}) => {

    const {t} = useTranslation();
    const classes = useStyles();
    const [isSaveDisabled,setIsSaveDisabled] = useState(true)
    const isEditing = useMemo(() => !!editObject, [editObject]);
    
    const {extensionsList, ringGroupsList} = useSelector(
        (state: ReduxState) => state.wallboard,
    );
    
    const initialValues: BaseWallboardWidget = isEditing && editObject ? editObject : {
        id: '',
        title: '',
        dataType: WidgetDataType.callVolumes,
        presentation: WidgetPresantationType.doughnut,
        autoRefreshTime: DefaultAutoRefreshPresetForDataType[WidgetDataType.callVolumes] as number,
        layout: {
            x: 0,
            y: 0,
            ...defaultIFrameWidgetSize
        } as BaseWallboardWidgetViewProps,
        data: {
            url: '',
            show: WidgetExtensionPresenseFilter.all,
            ringGroup: '',
            timeRange: WidgetCallVolumesTimeRange.min60,
            numberOfCalls: false
        } as ExternalIframeData | WidgetExtensionPresenseData | WidgetCallVolumesData
    };

    const widgetDataTypeList = useMemo(
        () =>
            Object.values(WidgetDataType)
                .map((v) => ({
                    name: t(`enums:widgetDataTypeEnum.${v}`),
                    value: v,
                    category: v === WidgetDataType.externalIframe 
                        ? t('screens:wallboard.category_external')
                        : v === WidgetDataType.callVolumes
                        ? t('screens:wallboard.category_historical')
                        : t('screens:wallboard.category_near_real_time')
                } as GroupedDropDownDictionaryItem<WidgetDataType | undefined>)),
        [],
    );

    const widgetExtensionPresenceFilterList = useMemo(
        () =>
            Object.values(WidgetExtensionPresenseFilter)
                .map((v) => ({
                    name: t(`enums:widgetExtensionPresenseFilter.${v}`),
                    value: v,
                } as DropDownDictionaryItem<WidgetExtensionPresenseFilter | undefined>)),
        [],
    );
    

    const ringGroupsSelectList = useMemo(
        () => {
            const init: DropDownDictionaryItem<string>[] = [];
            ringGroupsList?.map(e => {
                init.push({
                    name: e.id + ((e.name?.length ?? 0) > 0 ? (' - ' + e.name) : ''),
                    value: e.id
                } as DropDownDictionaryItem<string>)
            });
            return init;
        }, [ringGroupsList],
    );

    const extensionSelectList = useMemo(
        () => {
            const init: DropDownDictionaryItem<string>[] = [];
            extensionsList?.map(e => {
                init.push({
                    name: e.extension_id + ((e.extension_name?.length ?? 0) > 0 ? (' - ' + e.extension_name) : ''),
                    value: e.extension_id
                } as DropDownDictionaryItem<string>)
            });
            return init;
        }, [extensionsList],
    );
    
    const {
        values,
        handleSubmit,
        setFieldError,
        handleChange,
        errors,
        setFieldValue,
        resetForm,
        dirty,
        validateForm,
        setErrors
    } = useFormik<BaseWallboardWidget>({
        initialValues,
        onSubmit: (form) => {
            onClose?.(true, form);
            resetForm();
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false
    });

    const widgetCallVolumesTimeRangeList = useMemo(
        () => {
            const allValues = Object.values(WidgetCallVolumesTimeRange);
            const half = allValues.length / 2;
            const allStrings = Object.values(WidgetCallVolumesTimeRange)
                .splice(half, half);
            return allStrings
                .map((v) => ({
                    name: t(`enums:widgetCallVolumesTimeRange.${v}`),
                    value: v as WidgetCallVolumesTimeRange,
                } as DropDownDictionaryItem<WidgetCallVolumesTimeRange | undefined>))
        },
        [],
    );


    useEffect(()=>{

        validateForm(values).then(res => {
            const hasErrors = res && JSON.stringify(res) !== JSON.stringify({});
            if(dirty && !hasErrors  )
            {
                setIsSaveDisabled(false)
            }else {

                // @ts-ignore
                if(dirty && Object.keys(res).length == 1 && res.data?.url != undefined && values.data?.url.length > 0 )
                {
                    setIsSaveDisabled(false)
                }else{
                    setIsSaveDisabled(true)
                }
                setErrors({})
            }
        });


    }, [dirty,values])


    const widgetAutoRefreshPresetList = useMemo(
        () => {
            const allValues = Object.values(WidgetAutoRefreshPreset);
            const half = allValues.length / 2;
            const allStrings = Object.values(WidgetAutoRefreshPreset)
                .splice(0, half);
            
            const defaultPreset = DefaultAutoRefreshPresetForDataType[values.dataType];
            const isDefault = (v1: string) => {
                //@ts-ignore
                const value = WidgetAutoRefreshPreset[v1];
                return value === defaultPreset;
            };

            return allStrings
                .map((v, i) => ({
                    name: t(`enums:widgetAutoRefreshPresetEnum.${v}`) + (
                        //@ts-ignore
                        isDefault(v)
                            ? t('screens:wallboard.default')
                            : ''
                    ),
                    value: allValues[half + i] as number,
                } as DropDownDictionaryItem<number | undefined>));
        }, [values],
    );

    const actionButtons = useMemo(() => [
        <DialogButton
            key="cancel"
            label={t('common:cancel')}
            onClick={() => {
                onClose?.(false, undefined);
                resetForm();
            }}
        />,
        <DialogButton
            key="save"
            label={t('common:save')}
            disabled={isSaveDisabled}
            onClick={handleSubmit}
            className={classNames(classes.primaryButton, errors ? classes.disabledButton : null)}
        />,
    ], [values, onClose, handleSubmit, errors,isSaveDisabled]);

    const handleSwitchDataType = useCallback((newValue: WidgetDataType | undefined) => {
        if(!newValue) {
            setFieldValue('data', undefined);
            return;
        }

        const autoRefreshTime = DefaultAutoRefreshPresetForDataType[newValue] as number;
        setFieldValue('autoRefreshTime', autoRefreshTime);

        switch(newValue) {
            case WidgetDataType.externalIframe: 
                const defObject = {
                    ...initialValues.data,
                    url: ''
                }
                setFieldValue('data', defObject as ExternalIframeData);
                
                //setup default widget size:
                setFieldValue('layout', {
                    ...values.layout,
                    ...defaultIFrameWidgetSize
                });
                break;
            case WidgetDataType.extensionPresense:
                const defObject2 = {
                    ...initialValues.data,
                    show: WidgetExtensionPresenseFilter.all,
                    ringGroup: '',
                    extensions: []
                }
                setFieldValue('data', defObject2 as WidgetExtensionPresenseData);
                
                //setup default widget size:
                setFieldValue('layout', {
                    ...values.layout,
                    ...defaultWidgetExtensionPresenseDataSize
                });
                break;
            case WidgetDataType.callVolumes:
                const defObject3 = {
                    ...initialValues.data,
                    show: WidgetExtensionPresenseFilter.all,
                    ringGroup: '',
                    extensions: [],
                    timeRange: WidgetCallVolumesTimeRange.min60,
                }
                setFieldValue('data', defObject3 as WidgetCallVolumesData);   
                
                //setup default widget size:
                setFieldValue('layout', {
                    ...values.layout,
                    ...defaultWidgetCallVolumesDataSize
                });
                break;
        }
    }, [values]);

    const renderExtensions = useCallback(
        (selected: DropDownDictionaryItem<string>[]) => {
            return selected.map((v) => (
                <CustomizedChip
                    key={v.value}
                    label={v.name}
                    customClasses={{chip: classes.dropDownChip}}
                    handleDelete={
                        () => {
                            const actualValues = (values.data as WidgetExtensionPresenseData)?.extensions || [];
                            const choosen = actualValues.filter(e => e !== v.value);
                            const defObject2 = {
                                ...values.data,
                                extensions: choosen,
                            }
                            setFieldValue('data', defObject2);
                        }
                    }
                />
            ));
        },
        [values],
    );

    const selectedExtensions = useMemo(
        () => {
            const actualValues = (values.data as WidgetExtensionPresenseData)?.extensions || [];
            return extensionSelectList.filter(e => !!actualValues.find(k => k === e.value));
        },
    [values, extensionSelectList]);
    
    const selectedRingGroup = useMemo(() => {
        const found = ringGroupsSelectList.find(e => e.value === (values.data as WidgetExtensionPresenseData).ringGroup);
        return !!found ? found : null;
    }, [ringGroupsSelectList, values]);

    return (
            <DialogContainer
                className={classes.modalContainer}
                isOpen={isOpen}
                dataQa={'add-new-widget-container'}
                header={isEditing
                    ? t('screens:wallboard.editWidgetTitle')
                    : t('screens:wallboard.addNewWidgetTitle')}
                dialogActionsButtons={actionButtons}
                dialogLeftSideActionsButtons={editObject &&
                    [
                        <DialogButton
                            key="remove"
                            label={t(
                                'screens:wallboard.deleteWidget',
                            )}
                            onClick={() => {
                                if(editObject && onDelete) {
                                    onDelete(editObject);
                                }
                            }}
                            dataQa={'delete-widget-button'}
                            dataTestId={'delete-widget-button'}
                            removeAccent
                            icon={
                                <Delete
                                    htmlColor={Colors.Gray5}
                                />
                            }
                            className={classes.deleteWidgetButton}
                        />,
                    ]
                }
            >
                <form
                    className={classes.form}
                    autoComplete="off"
                    data-testid="add-new-widget-form"
                >
                    <Grid className={classes.itemsContainer}>
                        <Box className={classes.boxRow}>
                            <CustomizedTextField
                                id="title"
                                label={t('screens:wallboard.title')}
                                value={values.title}
                                required
                                setFieldError={setFieldError}
                                helperText={
                                    errors.title && errors.title.length > 0
                                        ? errors.title
                                        : ''
                                }
                                onChange={handleChange}
                                maxLength={32}
                                dataQa="title-field"
                                className={classes.halfScreenField}
                                skipPermission
                            />

                            {(values.dataType === WidgetDataType.extensionPresense || values.dataType === WidgetDataType.callVolumes) && 
                                <SelectField
                                    items={widgetExtensionPresenceFilterList}
                                    label={t('screens:wallboard.show')}
                                    value={widgetExtensionPresenceFilterList.find((v) =>
                                        v.value === (values.data as WidgetExtensionPresenseData)?.show)
                                    }
                                    getOptionLabel={(v: DropDownDictionaryItem<WidgetExtensionPresenseFilter | undefined>) => v?.name || ''}
                                    classes={{ container: classes.halfScreenField }}
                                    getOptionSelected={(
                                        option: DropDownDictionaryItem<WidgetExtensionPresenseFilter | undefined>,
                                        value: DropDownDictionaryItem<WidgetExtensionPresenseFilter | undefined>,
                                    ) => value.value === option.value}
                                    renderOption={(v: DropDownDictionaryItem<WidgetExtensionPresenseFilter | undefined>) => 
                                        <span>{v?.name}</span>
                                    }
                                    onChange={(_, v: DropDownDictionaryItem<WidgetExtensionPresenseFilter | undefined>) => {
                                        // @ts-ignore
                                        setFieldValue('data', {
                                            show: v.value,
                                            timeRange: values.data?.timeRange ?? WidgetCallVolumesTimeRange.min60,
                                            numberOfCalls:values.data?.numberOfCalls ?? false
                                        } as WidgetExtensionPresenseData);
                                    }}
                                    required
                                    helperText={
                                        //@ts-ignore
                                        errors.data?.show && errors.data?.show.length > 0 ? errors.data?.show : ''
                                    }
                                    disableAutoSettingValue
                                    disableClearable
                                    id='show'
                                />
                            }
                        </Box>
                    </Grid>
                    
                    <Grid className={classes.itemsContainer}>
                        <Box className={classes.boxRow} style={{height: 56}}>
                            <SelectField
                                items={widgetDataTypeList}
                                label={t('screens:wallboard.dataType')}
                                value={widgetDataTypeList.find((v) => v.value === values.dataType)}
                                getOptionLabel={(v: GroupedDropDownDictionaryItem<WidgetDataType | undefined>) => v?.name || ''}
                                classes={{ container: classes.halfScreenField }}
                                getOptionSelected={(
                                    option: GroupedDropDownDictionaryItem<WidgetDataType | undefined>,
                                    value: GroupedDropDownDictionaryItem<WidgetDataType | undefined>,
                                ) => value.value === option.value}
                                renderOption={(v: GroupedDropDownDictionaryItem<WidgetDataType | undefined>) => 
                                    <span>{v?.name}</span>
                                }
                                onChange={(_, v: GroupedDropDownDictionaryItem<WidgetDataType | undefined>) => {
                                    setFieldValue('dataType', v.value);
                                    handleSwitchDataType(v.value);
                                }}
                                required
                                helperText={
                                    errors.dataType && errors.dataType.length > 0
                                        ? errors.dataType
                                        : ''
                                }
                                disableAutoSettingValue
                                disableClearable
                                id='dataType'
                                groupBy={(option: GroupedDropDownDictionaryItem<WidgetDataType | undefined>) => option.category}
                                renderGroup={(params) => {
                                    return  (
                                        <div key={params.key}>
                                            <span className={classes.groupHeader}>{params.group}</span>
                                            {params.children}
                                        </div>
                                    );
                                }}
                            />
                            {(values.dataType === WidgetDataType.extensionPresense || values.dataType === WidgetDataType.callVolumes) && 
                            (values.data as WidgetExtensionPresenseData)?.show === WidgetExtensionPresenseFilter.selected_ring_groups &&
                                <SelectField
                                    id="ringGroup"
                                    label={t('screens:wallboard.selectedRingGroup')}
                                    getOptionLabel={(option: DropDownDictionaryItem<string>) => {
                                        return option.name;
                                    }}
                                    setFieldError={setFieldError}
                                    onChange={(_, option: DropDownDictionaryItem<string>) => {
                                        setFieldValue('data', {
                                            ...(values.data as WidgetExtensionPresenseData),
                                            ringGroup: option.value
                                        });
                                        setFieldError('data.ringGroup', undefined);
                                    }}
                                    required={(values.dataType === WidgetDataType.extensionPresense  || values.dataType === WidgetDataType.callVolumes) &&
                                        (values.data as WidgetExtensionPresenseData)?.show === WidgetExtensionPresenseFilter.selected_ring_groups}
                                    items={ringGroupsSelectList ?? []}
                                    value={selectedRingGroup}
                                    getOptionSelected={(
                                        option: DropDownDictionaryItem<string>,
                                        value: DropDownDictionaryItem<string>,
                                    ) => value.value === option.value}
                                    dataQa={'ring-group'}
                                    skipPermission
                                    disableClearable
                                    classes={{container: classNames(classes.halfScreenField, classes.dropDownMultiSelect)}}
                                    helperText={
                                        //@ts-ignore
                                        errors.data?.ringGroup && errors.data?.ringGroup?.length > 0 ? errors.data?.ringGroup : ''
                                    }
                                />
                            }
                            {(values.dataType === WidgetDataType.extensionPresense || values.dataType === WidgetDataType.callVolumes) && 
                            (values.data as WidgetExtensionPresenseData)?.show === WidgetExtensionPresenseFilter.selected_extensions &&
                                <SelectField
                                    id="extensions"
                                    label={t('screens:wallboard.selectedExtensions')}
                                    getOptionLabel={(option: DropDownDictionaryItem<string>) => {
                                        return option.name;
                                    }}
                                    setFieldError={setFieldError}
                                    onChange={(_, options: DropDownDictionaryItem<string>[]) => {
                                        setFieldValue('data', {
                                            ...(values.data as WidgetExtensionPresenseData),
                                            extensions: options?.map(c => c.value) || []
                                        });
                                        setFieldError('data.extensions', undefined);
                                    }}
                                    required={(values.dataType === WidgetDataType.extensionPresense || values.dataType === WidgetDataType.callVolumes) &&
                                        (values.data as WidgetExtensionPresenseData)?.show === WidgetExtensionPresenseFilter.selected_extensions}
                                    items={extensionSelectList ?? []}
                                    value={selectedExtensions}
                                    getOptionSelected={(
                                        option: DropDownDictionaryItem<string>,
                                        value: DropDownDictionaryItem<string>,
                                    ) => value.value === option.value}
                                    dataQa={'extensions-drop-down'}
                                    skipPermission
                                    disableClearable
                                    multiple
                                    classes={{container: classNames(classes.halfScreenField, classes.dropDownMultiSelect)}}
                                    //@ts-ignore
                                    renderTags={renderExtensions}
                                    helperText={
                                        //@ts-ignore
                                        errors.data?.extensions && errors.data?.extensions?.length > 0 ? errors.data?.extensions : ''
                                    }
                                />
                            }
                        </Box>
                    </Grid>
                    
                    <Grid className={classes.itemsContainer}>
                        <Box className={classes.boxRow}>

                            <div className={classes.widgetTypeSwitch}>
                                <div className={classes.widgetTypeSwitchHeader}>
                                    {t('screens:wallboard.presentation')}
                                </div>
                                <div className={classes.switchBox}>
                                    <CustomizedTooltip
                                        title={t('screens:wallboard.listPresentationType')}
                                        copy={false}
                                        disableHoverListener={values.dataType === WidgetDataType.externalIframe}
                                    >
                                        <div className={classNames(classes.switchBoxItem, classes.switchBoxItemLeft,
                                            values.presentation === WidgetPresantationType.list && classes.switchBoxItemSelected,
                                            values.dataType === WidgetDataType.externalIframe && classes.switchBoxItemDisabled
                                            )}
                                            onClick={() => {
                                                setFieldValue('presentation', WidgetPresantationType.list);
                                            }}>
                                            <ListWidgetType />
                                        </div>
                                    </CustomizedTooltip>
                                    <CustomizedTooltip
                                        title={t('screens:wallboard.doughnutPresentationType')}
                                        copy={false}
                                        disableHoverListener={values.dataType === WidgetDataType.externalIframe}
                                    >
                                        <div className={classNames(classes.switchBoxItem, classes.switchBoxItemRight,
                                            values.presentation === WidgetPresantationType.doughnut && classes.switchBoxItemSelected,
                                            values.dataType === WidgetDataType.externalIframe && classes.switchBoxItemDisabled
                                            )}
                                            onClick={() => {
                                                setFieldValue('presentation', WidgetPresantationType.doughnut);
                                            }}>
                                            <DougnutWidgetType />
                                        </div>
                                    </CustomizedTooltip>
                                </div>
                            </div>

                            <SelectField
                                items={widgetAutoRefreshPresetList}
                                label={t('screens:wallboard.autoRefresh')}
                                value={widgetAutoRefreshPresetList.find((v) => v.value === values.autoRefreshTime)}
                                getOptionLabel={(v: DropDownDictionaryItem<number | undefined>) => v?.name || ''}
                                classes={{ container: classNames(classes.quarterScreenField, classes.marginTop30) }}
                                getOptionSelected={(
                                    option: DropDownDictionaryItem<number | undefined>,
                                    value: DropDownDictionaryItem<number | undefined>,
                                ) => value.value === option.value}
                                renderOption={(v: DropDownDictionaryItem<number | undefined>) => 
                                    <span>{v?.name}</span>
                                }
                                onChange={(_, v: DropDownDictionaryItem<number | undefined>) => {
                                    setFieldValue('autoRefreshTime', v.value || (WidgetAutoRefreshPreset.sec30 as number));
                                }}
                                required
                                helperText={
                                    errors.dataType && errors.dataType.length > 0
                                        ? errors.dataType
                                        : ''
                                }
                                disableAutoSettingValue
                                disableClearable
                                id='autoRefreshTime'
                            />
                        </Box>
                    </Grid>
                    
                    {values.dataType === WidgetDataType.externalIframe && 
                        <Grid className={classes.itemsContainer}>
                            <Box className={classes.boxRow}>
                                <CustomizedTextField
                                    id="data.url"
                                    label={t('screens:wallboard.url')}
                                    value={values.data?.url}
                                    required
                                    setFieldError={setFieldError}
                                    helperText={
                                        //@ts-ignore
                                        errors.data?.url && errors.data?.url.length > 0 ? errors.data?.url : ''
                                    }
                                    onChange={(elem) => {
                                        setFieldValue('data', {
                                            url: elem.target.value
                                        } as ExternalIframeData);
                                    }}
                                    maxLength={2048}
                                    dataQa="url-field"
                                    className={classes.halfScreenField}
                                    skipPermission
                                />
                            </Box>
                        </Grid>
                    }

                    {values.dataType === WidgetDataType.callVolumes && 
                        <>
                            <Grid className={classes.itemsContainer}>
                                <Box className={classes.boxRow} style={{height: 56}}>
                                    <SelectField
                                        id='timeRange'
                                        items={widgetCallVolumesTimeRangeList}
                                        label={t('screens:wallboard.timeRange')}
                                        value={widgetCallVolumesTimeRangeList.find((v) => v.value === (values.data as WidgetCallVolumesData)?.timeRange)}
                                        getOptionLabel={(v: GroupedDropDownDictionaryItem<WidgetCallVolumesTimeRange | undefined>) => v?.name || ''}
                                        classes={{ container: classes.halfScreenField }}
                                        setFieldError={setFieldError}
                                        getOptionSelected={(
                                            option: GroupedDropDownDictionaryItem<WidgetCallVolumesTimeRange | undefined>,
                                            value: GroupedDropDownDictionaryItem<WidgetCallVolumesTimeRange | undefined>,
                                        ) => value?.value === option?.value}
                                        renderOption={(v: GroupedDropDownDictionaryItem<WidgetCallVolumesTimeRange | undefined>) => 
                                            <span>{v?.name}</span>
                                        }
                                        onChange={(_, v: GroupedDropDownDictionaryItem<WidgetCallVolumesTimeRange | undefined>) => {
                                            setFieldValue('data', {
                                                ...(values.data as WidgetCallVolumesData),
                                                timeRange: v.value
                                            });
                                            setFieldError('data.timeRange', undefined);
                                        }}
                                        required
                                        helperText={
                                            errors?.data && errors.data.hasOwnProperty('timeRange')
                                                // @ts-ignore
                                                ? errors?.data.timeRange
                                                : ''
                                        }
                                        disableAutoSettingValue
                                        disableClearable
                                        groupBy={(option: GroupedDropDownDictionaryItem<WidgetDataType | undefined>) => option.category}
                                        renderGroup={(params) => {
                                            return  (
                                                <div key={params.key}>
                                                    <span className={classes.groupHeader}>{params.group}</span>
                                                    {params.children}
                                                </div>
                                            );
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid className={classes.itemsContainer}>
                                <Box className={classes.boxRow} style={{height: 56}}>
                                    <CustomizedCheckbox
                                        checked={(values.data as WidgetCallVolumesData).numberOfCalls}
                                        dataQa="number-of-calls-checkbox"
                                        onChange={(value) =>
                                            setFieldValue('data', {
                                                ...(values.data as WidgetCallVolumesData),
                                                numberOfCalls: value.target.checked
                                            })
                                        }
                                        label={t(
                                            'screens:wallboard.numberOfCalls',
                                        )}
                                        className={classes.halfScreenField}
                                    />
                                </Box>
                            </Grid>
                        </>
                    }

                </form>
            </DialogContainer>
    );
};

export default AddNewWidgetDialog;
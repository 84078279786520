import * as actions from '../../actions';

import {all, call, delay, put, select, takeLatest} from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';

import {showErrorToast} from '../../../utils/showErrorToast';
import {SipTrunksFilters} from "../../reducers/sipTrunks/reducer";
import {APIErrorInterface, ReduxState} from "../../types";
import {AxiosResponse} from "axios";
import {AccountListRequest, AccountListResponse} from "../../types/Account";
import {fetchAccountList, getCountriesList, getSubdivisionsData, getTimeZonesList} from "../generic/saga";
import {ExtensionFilters, ExtensionsListItem, SipTrunksListItem} from "../../reducers/extensions/extensions/reducer";
import {YesNo} from "../../types/CallScreening";
import {ServiceFeature, ServiceFeatureName} from "../../types/ServiceFeature";
import {sipTrunkItemsTransformer} from "../../../utils/transformers";
import {getActiveCallsRequest} from "../calls/saga";
import {ActiveCall} from "../../types/CallHistory";
import i18n from "../../../services/i18n";
import JSONFormData from "../../../utils/JSONFormData";
import {
    CreateExtensionFaultCode,
    ExtensionType,
    PasswordFaultCode,
    UpdateExtensionFaultCode
} from "../../types/Extension";
import {api} from "../../services/axios";
import {Account} from "../../../services/endpoints";
import {toast} from "react-hot-toast";
import {BillStatus, CreateSipTrunkFaultCode, CreateSipTrunkFaultString} from "../../types/SipTrunk";
import {assignDidNumber, updateAccountDIDNumbers} from "../didNumbers/saga";
import {getRoute, Routes} from "../../../routes/routes";
import {editServiceFeatures, getAllServiceFeatures} from "../extensions/serviceFeatures/saga";
import {
    editServicePassword,
    EXTENSION_NOT_FOUND_BY_ID_API_ERROR,
    fetchAccountInfo,
    fetchDIDNumbers,
    getCustomerInfoDetails,
    getExtensionDialingRule,
    setExtensionStatusCall
} from "../extensions/extensions/saga";
import {CustomerDIDNumberType} from "../../types/CustomerDIDNumber";
import {getServiceFeatureValue} from "../../../utils/extensions/getServiceValue";
import {getCallBarringRules, updateCallBarringRules} from "../extensions/callBarring/saga";
import {
    editCallSettingsServiceFeatures,
    editVoicemailSettings,
    getExtensionVoicemailSettings,
    getGreetingsFilesNames
} from "../extensions/voicemail/saga";
import {editExtensionPlan, getExtensionProducts} from "../extensions/plan/saga";
import {
    editExtensionCallForwardingSettings,
    getExtensionCallForwardingSettings
} from "../extensions/callForwarding/saga";
import {getPermittedSipProxies} from "../extensions/sip/saga";
import {getCallScreeningTabData} from "../extensions/extensionTabs/saga";
import {compareObjectsAndReturnDifferencesInValues} from "../../../utils/compareObjects";
import {editCallScreeningDetails} from "../extensions/callScreening/saga";
import qs from "qs";
import {EditSipTrunkForm} from "../../../views/SipTrunks/SipTrunkDetails/utils";
import {TimeZone} from "../../types/TimeZone";
import {CustomerInfo} from "../../types/CustomerInfo";
import {TransportProtocol} from "../../types/AccountFollowMeSettings";
import {recordingValidationMsg} from "../../../utils/recordingValidationMsg";


function transformSipFiltersToExtensionFilters(filters: SipTrunksFilters): ExtensionFilters {

    const billStatusExist = !!filters.statusFilter?.details.bill_status;

    return {
        id: filters?.trunkIdFilter ? `%${filters.trunkIdFilter}%` : undefined,
        sip_status: !billStatusExist && filters?.statusFilter && filters.statusFilter.details.sip_status !== undefined
            ? filters.statusFilter.details.sip_status
            : undefined,
        did_number: filters?.didNumberFilter ? `%${filters.didNumberFilter}%` : undefined,
        blocked: (!billStatusExist && filters?.statusFilter && filters.statusFilter.details.blocked) || undefined,
        bill_status: filters.statusFilter?.details.bill_status ? filters.statusFilter?.details.bill_status : filters.statusFilter?.details.sip_status !== undefined ? BillStatus.Open : undefined
    }
}

const params: Partial<AccountListRequest> = {
    has_extension: 0,
    get_not_closed_accounts: 1,
    get_service_features: [
        ServiceFeatureName.CallBarring,
        ServiceFeatureName.CallProcessing,
        ServiceFeatureName.CallRecording,
        ServiceFeatureName.VoiceFup,
        ServiceFeatureName.UnifiedMessaging,
        ServiceFeatureName.SipStaticContact,
    ],
    service_features_filter: [
        {
            effective_flag_value: YesNo.No,
            name: "auto_attendant"
        }
    ]
};


const filterOnlyWithoutUnifiedMessaging = (item: SipTrunksListItem) => {
    let removeFromOutput = false;
    item.serviceFeatures?.forEach((o) => {

        if (
            o.name == ServiceFeatureName.UnifiedMessaging &&
            o.attributes.filter(
                (a) =>
                    a.name == 'fax_only_mode' &&
                    a.effective_values.filter((v) => v == 'Y').length,
            ).length
        ) {
            removeFromOutput = true;
        }
    });

    return !removeFromOutput;
};

export function* getSipTrunksList(
    action: ActionType<typeof actions.getSipTrunksList.request>,
) {
    const {trunkIdFilter, statusFilter, didNumberFilter} = action.payload

    try {

        const limit = 300;
        const offset = 0;

        const sipFilters: SipTrunksFilters = yield select(
            (state: ReduxState) => state.sipTrunks,
        );

        const filters = transformSipFiltersToExtensionFilters({
            ...sipFilters,
            trunkIdFilter,
            statusFilter,
            didNumberFilter
        });


        const resTotal: AxiosResponse<AccountListResponse> = yield fetchAccountList(
            filters,
            {
                ...params,
                limit: limit,
                offset: offset,
            },
        );

        const total = resTotal.data.total;
        let accountList = [...resTotal.data.account_list];


        if (resTotal.data.account_list.length < total) {
            for (let i = 0; i < total / limit; i++) {

                const resMore: AxiosResponse<AccountListResponse> = yield fetchAccountList(
                    filters,
                    {...params, offset: params.offset || limit}
                );

                accountList = accountList.concat(resMore.data.account_list);

            }
        }

        const list = sipTrunkItemsTransformer(accountList);
        let filteredData = list.filter(filterOnlyWithoutUnifiedMessaging);

        const registerFilters = transformSipFiltersToExtensionFilters({
            statusFilter: {
                title: 'Registered',
                details: {sip_status: 1},
            }
        });

        const sipWithRegisteredDevices: AxiosResponse<{
            total: number;
        }> = yield fetchAccountList(
            registerFilters,
            {...params, limit: 1, offset: 0},
        );

        const calls: AxiosResponse<{
            active_session_list: ActiveCall[];
        }> = yield getActiveCallsRequest(3);

        if (calls.data.active_session_list.length) {
            filteredData = addCalls(calls.data.active_session_list, filteredData);
        }

        yield put(
            actions.getSipTrunksList.success({
                total: filteredData.length,
                items: filteredData,
                filters: {
                    ...sipFilters,
                    trunkIdFilter,
                    statusFilter,
                    didNumberFilter
                },
                registeredTotal: sipWithRegisteredDevices.data.total
            }),
        );

    } catch (e) {
        //@ts-ignore
        showErrorToast(e.response?.data.faultstring);
        //@ts-ignore
        yield put(actions.getSipTrunksList.failure(e));
    }
}

function addCalls(active_session_list: ActiveCall[], filteredData: SipTrunksListItem[]) {
    active_session_list.forEach(item => filteredData.forEach((account, index) => {
        if (account.i_account == item.i_account) {

            filteredData[index].activeCalls.forEach(call => {
                if (item.call_type && item.call_type == call.name) {
                    call.count++
                } else if (item.call_type == undefined && call.name == i18n.t<string>('screens:sipTrunks.maxCalls')) {
                    call.count++
                }
            });

        }
    }));

    return filteredData;
}


export function* refreshSipTrunkStatus(
    action: ActionType<typeof actions.refreshSipTrunkStatus.request>,
) {
    try {
        const {session_id, csrf_token} = yield select((state) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            i_account: action.payload.i_account,
            detailed_info: 1,
            limit_alias_did_number_list: 100,
        });

        const accountDetails: AxiosResponse<{
            account_info: ExtensionType;
        }> = yield api.post(Account.GetAccountInfo, body);

        yield put(
            actions.refreshSipTrunkStatus.success({
                i_account: action.payload.i_account,
                account_info: accountDetails.data.account_info,
            }),
        );
    } catch (e) {
        yield put(actions.refreshSipTrunkStatus.failure());
    }
}


export function* createNewSipTrunk(
    action: ActionType<typeof actions.createNewSipTrunk.request>,
) {
    const product_id = action.payload.mainProductId?.toString() || i18n.t<string>("common:undefined");
    const trunk_id = action.payload.trunkId?.toString() || i18n.t<string>("common:undefined");

    try {
        
        const {session_id, csrf_token} = yield select((state) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            account_info: {
                id: action.payload.trunkId,
                i_product: action.payload.mainProductId,
                h323_password: action.payload.password,
                billing_model: 1
            }
        });

        const response: AxiosResponse<{ i_account: number }> = yield api.post(
            Account.AddAccount,
            body,
        );

        if (action.payload.didNumber?.length) {
            yield assignDidNumber(action.payload.didNumber, response.data.i_account)

        }

        toast(i18n.t<string>('screens:sipTrunks.sipTrunkCreated'));

        yield put(actions.createNewSipTrunk.success());

        location?.replace(
            getRoute(Routes.SipTrunkDetails, {
                id: action.payload.trunkId,
            }),
        );
    } catch (err: any) {
        const {faultcode, faultstring} = err.response.data;

        if (
            isUnhandledError(faultcode, faultstring, product_id, trunk_id)
        ) {
            action.payload.errorCallback?.();
        }

        yield put(actions.createNewSipTrunk.failure(err.response?.data));
    }
}

function isUnhandledError(errorCode: string, errorString: string, product_id = '', trunk_id = '') {

    if (
        errorCode.includes(CreateExtensionFaultCode.MaxOfferedQuantity) ||
        errorString == CreateSipTrunkFaultString.DuplicateId ||
        errorCode == CreateExtensionFaultCode.ExtHGNumberInUse ||
        errorCode == PasswordFaultCode.InvalidChars ||
        errorCode == CreateSipTrunkFaultCode.MaxQuantityError.replace('{product_id}', product_id) ||
        errorString == CreateSipTrunkFaultString.InvalidId
    ) {
        return false
    }

    return true;
}

function* fetchSipTrunkById(trunkId: string) {
    //fetch account info
    const accountInfo: ExtensionType = yield fetchAccountInfo(
        undefined, trunkId, [ServiceFeatureName.VoiceFup]
    );

    if (!accountInfo) {
        return undefined;
    }

    let data = sipTrunkItemsTransformer(accountInfo);

    const calls: AxiosResponse<{
        active_session_list: ActiveCall[];
    }> = yield getActiveCallsRequest(3);

    if (calls.data.active_session_list.length) {
        data = addCalls(calls.data.active_session_list, data);
    }

    const sipTrunk = data[0];

    const numbers: CustomerDIDNumberType[] = yield fetchDIDNumbers(
        sipTrunk.i_account,
    );

    const payload: SipTrunksListItem = {
        ...sipTrunk,
        callProcessingModeInfo: data.length
            ? sipTrunk.account_info?.call_processing_mode_info
            : undefined,
        account_info: {...accountInfo},
        numbers: sipTrunk.numbers ? [...sipTrunk.numbers, ...numbers.map((n) => n.did_number)] :
            numbers.map((n) => n.did_number),
    };

    return payload;

}

export function* fetchSipTrunkBasicData(
    extensionID: string
) {
    //fetch extension
    //@ts-ignore
    const extension = yield fetchSipTrunkById(extensionID);

    if (!extension) {
        return undefined;
    }

    const payload: ExtensionsListItem = {
        ...extension,
        callProcessingModeInfo: extension
            ? extension.call_processing_mode_info
            : undefined
    };

    return payload;
}

export function* basicSipTrunkDetailsData(
    action: ActionType<typeof actions.getSipTrunkBasicDetailsData.request>,
) {
    const {id} = action.payload;
    const payload: SipTrunksListItem = yield fetchSipTrunkBasicData(id);

    if (!payload) {
        yield put(actions.getSipTrunkBasicDetailsData.failure({
            faultcode: EXTENSION_NOT_FOUND_BY_ID_API_ERROR,
            faultstring: i18n.t<string>('tooltips:extensions.extensionNotFound', {
                value: id
            })
        } as APIErrorInterface));
        return;
    }

    yield getAllServiceFeatures(payload.i_account);
    yield put(actions.getSipTrunkBasicDetailsData.success(payload));
}

export function* setSipTrunkStatus(
    action: ActionType<typeof actions.setSipTrunkStatus.request>,
) {
    try {

        yield setExtensionStatusCall(action.payload)
        yield put(actions.setSipTrunkStatus.success(action.payload));

        toast(
            i18n.t<string>(
                action.payload.account_info.blocked === 'Y'
                    ? 'screens:sipTrunks.extensionDisabled'
                    : 'screens:sipTrunks.extensionEnabled',
            ),
        );
    } catch (err: any) {
        toast(err.response?.data?.faultstring);
    }
}

export function* getSipTrunkCallForwardingTabData(
    action: ActionType<typeof actions.getSipTrunkCallForwardingTabData.request>,
) {
    yield all([
        call(
            getExtensionCallForwardingSettings,
            actions.getExtensionFollowMeSettings.request({
                accountId: action.payload.i_account,
            }),
        ),
        call(
            getPermittedSipProxies,
            actions.getPermittedSipProxies.request({
                i_account: action.payload.i_account,
            }),
        ),
    ]);

    yield put(actions.getSipTrunkCallForwardingTabData.success());
}

export function* getSipTrunkCallScreeningTabData(
    action: ActionType<typeof actions.getSipTrunkCallScreeningTabData.request>,
) {
    yield call(
        getCallScreeningTabData,
        actions.getCallScreeningTabData.request({
            i_customer: action.payload.i_customer,
            i_account: action.payload.i_account,
        }),
    );

    yield put(actions.getSipTrunkCallScreeningTabData.success());
}

export function* getSipTrunkPlanTabData() {
    yield call(getExtensionProducts);
    yield put(actions.getSipTrunkPlanTabData.success());
}

export function* getDynamicAddressList(
    action: ActionType<typeof actions.getSipTrunkDynamicAddresses.request>,
) {
    try {
        const {session_id, csrf_token} = yield select((state) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            get_not_closed_accounts: 1,
            get_statuses: 1
        });

        const response: AxiosResponse<AccountListResponse> = yield api.post(Account.GetAccountList, body);

        const list = response.data.account_list.map(item => ({
            iAccount: item.i_account,
            name: item.id,
            status: item.sip_status == 1 ? i18n.t<string>('screens:dashboard.registered') : undefined
        }));

        yield put(
            actions.getSipTrunkDynamicAddresses.success({
                account_list: list
            }),
        );
    } catch (e: any) {
        yield put(actions.getSipTrunkDynamicAddresses.failure());
    }
}

export function* getSipTrunkCallSettingsTabData(
    action: ActionType<typeof actions.getSipTrunkCallSettingsTabData.request>,
) {
    const serviceFeatures: ServiceFeature[] = yield select(
        (state: ReduxState) => state.extensions.serviceFeatures || [],
    );

    const callBarringStatus = getServiceFeatureValue(
        ServiceFeatureName.CallBarring,
        serviceFeatures,
    );

    if (callBarringStatus && callBarringStatus != 'N') {
        yield call(
            getCallBarringRules,
            actions.getCallBarringRules.request({
                i_account: action.payload.i_account,
            }),
        );
    }

    if (action.payload.domain.length) {
        yield all([
            call(
                getExtensionVoicemailSettings,
                actions.getExtensionVoicemailSettings.request({
                    accountId: action.payload.id,
                    domain: action.payload.domain,
                }),
            ),
            call(
                getGreetingsFilesNames,
                actions.getGreetingsFilesNames.request({
                    accountId: action.payload.id,
                    domain: action.payload.domain,
                }),
            ),
        ]);
    }

    yield call(getDynamicAddressList, actions.getSipTrunkDynamicAddresses.request())

    const i_dialing_rule = getServiceFeatureValue(
        ServiceFeatureName.VoiceDialing,
        serviceFeatures,
        'i_dial_rule',
    );

    if (i_dialing_rule) {
        yield getExtensionDialingRule(i_dialing_rule);
    }

    yield put(actions.getSipTrunkCallSettingsTabData.success());
}


export function* getSipTrunkTabData(
    action: ActionType<typeof actions.getSipTrunkTabData.request>,
) {
    const {i_customer, i_account} = action.payload;

    if (i_account) {
        yield getCustomerInfoDetails(i_customer);
        // @ts-ignore

        yield all([
            call(getCountriesList),
            call(getTimeZonesList),
        ]);

        const country: string | undefined = yield select(
            (state: ReduxState) =>
                state.sipTrunks.sipTrunkDetails?.sipTrunk?.account_info?.country,
        );

        if (country) {
            yield call(
                getSubdivisionsData,
                actions.getSubdivisionData.request({
                    iso_3166_1_a2: country,
                }),
            );
        }
    }

    yield put(actions.getSipTrunkTabData.success());
}


export function* editSipTrunkUserDetails(
    data: Partial<EditSipTrunkForm>,
    action: ActionType<typeof actions.editSipTrunk.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);

    const account: Partial<ExtensionType> = {
        i_account: action.payload.accountId,
    };

    if (data.postalCode !== undefined) {
        account.zip = data.postalCode;
    }
    if (data.state !== undefined) {
        account.state = data.state;
    }
    if (data.city !== undefined) {
        account.city = data.city;
    }
    if (data.address !== undefined) {
        account.baddr1 = data.address;
    }
    if (data.country !== undefined) {
        account.country = data.country;
    }
    if (data.emailAddress !== undefined) {
        account.email = data.emailAddress;
    }
    if (data.sipTrunk !== undefined) {
        account.id = data.sipTrunk
    }

    if (data.timezone !== undefined) {
        const timezones: TimeZone[] = yield select(
            (state: ReduxState) => state.generic.timeZonesList,
        );

        const timezoneId = timezones.find(
            (v) => v.time_zone_name === data.timezone,
        )?.i_time_zone;

        if (timezoneId) {
            account.time_zone_name = data.timezone;
            account.i_time_zone = timezoneId;
        }
    }

    body.setParams({account_info: account});

    if (Object.keys(account).length > 1) {
        yield api.post(Account.UpdateAccount, body);
    }
}

function* editSipTrunkNumbers(
    data: Partial<EditSipTrunkForm>,
    action: ActionType<typeof actions.editSipTrunk.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);

    if (!!data.displayNumber) {
        const params = {
            i_account: action.payload.accountId,
            service_features: [
                {
                    name: ServiceFeatureName.Cli,
                    attributes: [
                        {
                            effective_values: [data.displayNumber],
                            name: 'centrex',
                            values: [data.displayNumber],
                        },
                    ],
                    effective_flag_value: 'Y',
                    flag_value: 'Y',
                },
            ],
        };

        body.setParams(params);

        yield api.post(Account.UpdateServiceFeatures, body);
    }

    if (data.displayNumber === null) {
        const params = {
            i_account: action.payload.accountId,
            service_features: [
                {
                    name: ServiceFeatureName.Cli,
                    attributes: [
                        {
                            name: 'centrex',
                        },
                    ],
                    flag_value: '^'
                },
            ],
        };

        body.setParams(params);

        yield api.post(Account.UpdateServiceFeatures, body);
    }

    if (data.didNumber) {
        const customerInfo: CustomerInfo & {
            didNumbers: CustomerDIDNumberType[];
        } = yield select(
            (state: ReduxState) => state.extensions.customerInfoDetails,
        );

        yield updateAccountDIDNumbers(
            action.payload.initialValues.didNumber,
            data.didNumber,
            customerInfo.didNumbers,
            action.payload.accountId,
        );
    }
}

export function* editSipContactSettings(
    data: Partial<EditSipTrunkForm>,
    action: ActionType<typeof actions.editSipTrunk.request>,
    initData: Partial<EditSipTrunkForm>
) {

    if (data.sipContactStatus == false) {
        yield editServiceFeatures(action.payload.accountId, [
            {
                name: ServiceFeatureName.SipStaticContact,
                effective_flag_value: "N",
                flag_value: "N",
                attributes: []
            }
        ]);

        return;
    }

    const useTcpValue = initData.transportProtocol == TransportProtocol.TCP ? ["Y"] : [];
    const userValue = [initData.sipCldNumber || initData.dynamicAddressEntity?.name];

    let payload = {
        name: ServiceFeatureName.SipStaticContact,
        effective_flag_value: initData.sipContactStatus ? "Y" : "N",
        flag_value: initData.sipContactStatus ? "Y" : "N",
        attributes: [
            {
                name: "use_tcp",
                values: useTcpValue,
                effective_values: useTcpValue,
            },
            {

                effective_values: userValue,
                name: "user",
                values: userValue
            },
            {

                effective_values: [
                    initData.host
                ],
                name: "host",
                values: [initData.host]
            },
            {

                effective_values: [
                    initData.port
                ],
                name: "port",
                values: [initData.port]
            }
        ]
    }

    if (data.sipContactStatus) {
        payload.effective_flag_value = "Y";
        payload.flag_value = "Y";
    }

    if (data.dynamicAddress || initData.dynamicAddress && data.dynamicAddressEntity != undefined) {
        payload.attributes = [
            {
                name: "use_tcp",
                values: [],
                effective_values: [],
            },
            {

                effective_values: [
                    data.dynamicAddressEntity?.name
                ],
                name: "user",
                values: [data.dynamicAddressEntity?.name]
            },
            {

                effective_values: [],
                name: "host",
                values: []
            },
            {

                effective_values: [],
                name: "port",
                values: []
            }
        ];

        yield editServiceFeatures(action.payload.accountId, [
            payload
        ]);

        return;
    } else if (data.transportProtocol || data.sipCldNumber || data.host || data.port) {
        const useTcp = data.transportProtocol !== undefined ? data.transportProtocol === TransportProtocol.TCP ? "Y" : "N" : undefined;

        if (useTcp) {
            payload = {
                ...payload, attributes: payload.attributes.map(item => item.name == "use_tcp" ? {
                    ...item,
                    values: [useTcp],
                    effective_values: [useTcp]
                } : item)
            }
        }

        let user = data.sipCldNumber !== undefined ? data.sipCldNumber : undefined;
        user = data.staticAddress && user == undefined ? '' : user;

        if (user !== undefined) {
            payload = {
                ...payload, attributes: payload.attributes.map(item => item.name == "user" ? {
                    ...item,
                    values: [user],
                    effective_values: [user]
                } : item)
            }
        }

        const host = data.host !== undefined ? data.host : undefined;

        if (host !== undefined) {
            payload = {
                ...payload, attributes: payload.attributes.map(item => item.name == "host" ? {
                    ...item,
                    values: [host],
                    effective_values: [host]
                } : item)
            }
        }

        const port = data.port !== undefined ? data.port : undefined;

        if (port != undefined) {
            payload = {
                ...payload, attributes: payload.attributes.map(item => item.name == "port" ? {
                    ...item,
                    values: [port],
                    effective_values: [port]
                } : item)
            }
        }

        yield editServiceFeatures(action.payload.accountId, [
            payload
        ]);

        return;
    }
}

export function* editSipTrunk(
    action: ActionType<typeof actions.editSipTrunk.request>,
) {
    const sipTrunkId =
        action.payload.changedValues.sipTrunk ||
        action.payload.initialValues.sipTrunk;

    try {
        const {session_id, csrf_token} = yield select((state) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams(action.payload);

        const dataToSave = compareObjectsAndReturnDifferencesInValues(
            action.payload.initialValues,
            action.payload.changedValues,
        );

        // @ts-ignore
        yield editSipTrunkUserDetails(dataToSave, action);
        yield editSipTrunkNumbers(dataToSave, action);

        // @ts-ignore
        yield editCallSettingsServiceFeatures(dataToSave, action);
        yield editSipContactSettings(dataToSave, action, action.payload.initialValues);
        // @ts-ignore
        yield editCallScreeningDetails(dataToSave, action);

        // @ts-ignore
        yield updateCallBarringRules({
                callBarringItems: dataToSave.callBarringItems,
                callBarringStatus: action.payload.initialValues?.callBarringStatus !== action.payload.changedValues?.callBarringStatus
                    ? action.payload.changedValues?.callBarringStatus
                    : undefined,
                callBarringLocked: action.payload.changedValues?.callBarringLocked
            },
            // @ts-ignore
            action);
        // @ts-ignore
        yield editVoicemailSettings(dataToSave, action);
        // @ts-ignore
        yield editExtensionPlan(dataToSave, action);
        // // @ts-ignore
        // @ts-ignore
        yield editServicePassword(dataToSave, {
            ...action,
            // @ts-ignore
            payload: {
                ...action.payload,
                iAccount: action.payload.accountId,
            },
        });
        // @ts-ignore
        yield editExtensionCallForwardingSettings(dataToSave, action);

        toast(i18n.t<string>('screens:sipTrunks.sipTrunkEdited'));
        yield put(actions.editSipTrunk.success());

        yield delay(1000);

        if (!action.payload.blockRedirection) {
            location?.replace(
                `${sipTrunkId}?${qs.stringify({
                    tab: action.payload.redirectTab,
                })}`,
            );
        }

    } catch (err: any) {

        const errorMsg = recordingValidationMsg(err.response.data.faultcode, err.response?.data?.faultstring);

        showErrorToast(
            errorMsg,
            err.response?.data?.faultcode,
            [
                CreateSipTrunkFaultCode.DuplicateId.replace('{trunk_id}', sipTrunkId),
                CreateSipTrunkFaultCode.InvalidTrunkId.replace('{trunk_id}', sipTrunkId),
                UpdateExtensionFaultCode.ExtHGNumberInUse,
                PasswordFaultCode.InvalidChars
            ],
        );

        yield put(actions.editSipTrunk.failure(err.response?.data));
    }
}

export const sipTrunksSaga = [
    takeLatest(actions.getSipTrunksList.request, getSipTrunksList),
    takeLatest(actions.refreshSipTrunkStatus.request, refreshSipTrunkStatus),
    takeLatest(actions.createNewSipTrunk.request, createNewSipTrunk),
    takeLatest(actions.getSipTrunkBasicDetailsData.request, basicSipTrunkDetailsData),
    takeLatest(actions.setSipTrunkStatus.request, setSipTrunkStatus),
    takeLatest(actions.getSipTrunkTabData.request, getSipTrunkTabData),
    takeLatest(actions.getSipTrunkCallSettingsTabData.request, getSipTrunkCallSettingsTabData),
    takeLatest(actions.getSipTrunkPlanTabData.request, getSipTrunkPlanTabData),
    takeLatest(actions.getSipTrunkCallForwardingTabData.request, getSipTrunkCallForwardingTabData),
    takeLatest(actions.getSipTrunkCallScreeningTabData.request, getSipTrunkCallScreeningTabData),
    takeLatest(actions.editSipTrunk.request, editSipTrunk),
    takeLatest(actions.getSipTrunkDynamicAddresses.request, getDynamicAddressList),
];

import {makeStyles} from "@material-ui/core";
import {Colors, lightGray, secondaryColor1, white} from "../../../styles/Colors";
import {TFunction} from "react-i18next";
import {ExtensionsListItem, SipTrunksListItem} from "../../../store/reducers/extensions/extensions/reducer";
import {CellProps, Column} from "react-table";
import {Permission} from "../../../store/types/Permission";
import PermissionLinkCell from "../../../components/DataTable/PermissionLinkCell";
import history from "../../../history";
import {getRoute, Routes} from "../../../routes/routes";
import ExtensionDIDNumber from "../../../components/Extensions/ExtensionDIDNumber";
import EmptyRowContent from "../../../components/DataTable/EmptyRowContent";
import React from "react";
import {convertServiceFeaturesToIconsFeatures} from "../../../utils/extensions/convertServiceFeaturesToIconsFeatures";
import {ExtensionServices} from "../../../components/Extensions/ExtensionServices";
import {FetchDataStatus} from "../../../store/reducers/autoAttendants/reducer";
import BarLoader from "../../../components/BarLoader/BarLoader";
import StatusItem from "../../../components/Extensions/StatusItem";
import PermissionProvider from "../../../components/PermissionProvider/PermissionProvider";
import CustomizedIconButton from "../../../components/IconButton/IconButton";
import {ReactComponent as CallHistoryIcon} from "../../../assets/call_history.svg";
import {Edit} from "@material-ui/icons";
import CallsCounterCell from "../../../components/Calls/CallsCounterCell";
import ActiveCalls from "../../../components/Calls/ActiveCalls";

export const useStyles = makeStyles(() => ({
    idHeader: {
        paddingLeft: 40
    },
    mainContainer: {
        flexDirection: 'column',
        height: '100vh',
        display: 'flex',

        '& .MuiButton-label': {
            color: white,
        },
        flex: 1,
        position: 'relative',
        background: Colors.SmokeBackground,
    },
    scrollable: {
        overflowY: 'auto',
        flex: 1,
        padding: '48px 96px',
        '& .MuiTableContainer-root': {
            width: 'unset !important'
        }
    },

    content: {
        maxWidth: 1040,
    },

    header: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },

    switchMode: {
        display: 'flex',
        marginTop: 10,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 25,
        '& h2': {
            fontSize: 32,
            lineHeight: '24px',
            marginBlockEnd: 8,
            marginBlockStart: 4,
            fontWeight: 500,
        },
        '& p': {
            fontSize: 14,
            lineHeight: '16px',
            opacity: 0.6,
            marginBlockStart: 0,
        },
    },
    filters: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: -65,
    },

    processableSearchIcon: {
        marginTop: 10,
        marginRight: 17,
        marginLeft: 17,
        cursor: 'pointer',
    },

    headerRow: {
        textTransform: 'uppercase',
        border: 0,
    },

    headerCell: {
        fontWeight: 700,
        fontSize: 12,
        backgroundColor: lightGray,
        height: '60px',
    },
    row: {
        borderBottom: 0,
    },
    filterSeparation: {
        marginRight: 18,
        flex: 1,
        maxWidth: '390px',
        background: 'white',
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    extensionIdLink: {
        color: secondaryColor1,
        textDecoration: 'none',
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
    },
    email: {
        fontSize: 14,
        opacity: 0.6,
    },
    actionsButtonsContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },

    actionHeader: {textAlign: 'center'},
}));


export const generateColumns = (
    t: TFunction<string>,
    refreshStatus: (i_account: number) => void,
    showCallHistory: (item: SipTrunksListItem) => void,
    classes: ReturnType<typeof useStyles>,
): Column<SipTrunksListItem>[] => {
    return [
        {
            Header: (
                <div className={classes.idHeader}>
                    {t<string>('screens:sipTrunks.trunkId')}
                </div>
            ),
            accessor: 'id',
            width: 1.5,
            Cell: function Cell(params) {

                const permission = Permission.SipTrunks.SipTrunksDetails.value;
                return (
                    <PermissionLinkCell
                        text={params.row.original.id}
                        onClick={() =>
                            history.push(
                                getRoute(Routes.SipTrunkDetails, {id: params.row.original.id}),
                            )
                        }
                        className={classes.idHeader}
                        permissions={permission}
                    />
                );
            },
        },
        {
            Header: t<string>('screens:extensions.didNumber'),
            accessor: 'numbers',
            width: 1.2,

            Cell: function Cell(params) {
                const numbers = (
                    params.row.original.account_info?.alias_did_number_list ||
                    []
                ).map((v) => v.did_number);

                if (numbers.length > 0) {
                    return (
                        <ExtensionDIDNumber
                            showFooter={false}
                            extNumbers={numbers}
                        />
                    );
                }
                return <EmptyRowContent/>;
            },
        },
        {
            Header: t<string>('screens:sipTrunks.servicesAndCalls'),
            accessor: (row) => row.account_info!.email,
            width: 2,
            id: '3',
            Cell: function Cell(
                params: React.PropsWithChildren<CellProps<SipTrunksListItem, string[] | undefined>>,
            ) {
                const mode = params.row.original.callProcessingModeInfo?.name;
                const services = convertServiceFeaturesToIconsFeatures(
                    params.row.original.serviceFeatures || [],
                    params.row.original.mainService,
                    mode,
                );

                return (
                    <div>
                        <ExtensionServices
                            services={services}
                        />
                        <CallsCounterCell calls={params.row.original.calls}/>
                    </div>
                );
            },
        },
        {
            Header: t<string>('screens:extensions.statusAndDevice'),
            //@ts-ignore
            accessor: 'status',
            width: 1.4,
            id: '4',
            Cell: function Cell(
                params: React.PropsWithChildren<CellProps<ExtensionsListItem, string[] | undefined>>,
            ) {
                const {
                    sip_status,
                    blocked,
                    sip_agent,
                    bill_status
                } = params.row.original.account_info!;

                const {fetchSipStatus, i_account} = params.row.original;
                return fetchSipStatus == FetchDataStatus.InProgress ? (
                    <BarLoader
                        dataQa={'sip-status-refresh-bar'}
                        dataTestId={'sip-status-refresh-bar'}
                    />
                ) : (
                    <StatusItem
                        blocked={blocked}
                        sip_status={sip_status!}
                        fetchData={() => refreshStatus(i_account)}
                        device={sip_agent}
                        billStatus={bill_status}
                    />
                );
            },
        },

        {
            Header: t<string>('screens:sipTrunks.activeCalls'),
            accessor: 'calls',
            width: .9,
            Cell: function Cell(params) {
                const calls = params.row.original.activeCalls;
                const filtered = calls.filter(item => item.count != 0);

                if (filtered.length) {
                    return <ActiveCalls data={filtered}/>
                }

                return '';
            }
        },
        {
            Header: (
                <div className={classes.actionHeader}>
                    {t<string>('common:actions')}
                </div>
            ),
            accessor: 'i_account',
            width: .8,
            Cell: function Cell(params) {
                return (
                    <div className={classes.actionsButtonsContainer}>
                        <PermissionProvider
                            permission={
                                Permission.SipTrunks.ViewCallHistory.value
                            }
                            skipReadOnly
                        >
                            <CustomizedIconButton
                                onClick={() =>
                                    showCallHistory(params.row.original)
                                }
                                tooltipText={t<string>(
                                    'screens:extensions.viewCallHistory',
                                )}
                                dataTestId="call-list-item-button"
                                dataQa="call-list-item-button"
                            >
                                <CallHistoryIcon/>
                            </CustomizedIconButton>
                        </PermissionProvider>

                        <PermissionProvider
                            permission={
                                Permission.SipTrunks.SipTrunksDetails.value
                            }
                            skipReadOnly
                        >
                            <CustomizedIconButton
                                onClick={() =>
                                    history.push(
                                        getRoute(Routes.SipTrunkDetails, {id: params.row.original.id}),
                                    )
                                }
                                tooltipText={t<string>('common:edit')}
                                dataTestId="edit-list-item-button"
                                dataQa="edit-list-item-button"
                            >
                                <Edit htmlColor={Colors.ListIconColor}/>
                            </CustomizedIconButton>
                        </PermissionProvider>
                    </div>
                );
            },
        },
    ];
};
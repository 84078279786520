import {useSelector} from 'react-redux';
import {ReduxState} from '../store/types';
import {useMemo} from 'react';
import {EXTENSION_NOT_FOUND_BY_ID_API_ERROR} from "../store/sagas/extensions/extensions/saga";

export const useSipTrunkDetailsForm = () => {
    const extension = useSelector(
        (state: ReduxState) => state.sipTrunks.sipTrunkDetails?.sipTrunk,
    );

    return useMemo(() => {
        return {
            i_account: extension?.i_account,
            is_blocked: extension?.account_info?.blocked === 'Y',
            bill_status: extension?.account_info?.bill_status,
            didNumbers: extension?.numbers,
            sip_status: extension?.account_info?.sip_status,
            sip_agent: extension?.account_info?.sip_agent,
            i_c_ext: extension?.i_c_ext,
            extension_id: extension?.id,
            i_customer: extension?.i_customer,
            account_id: extension?.account_info?.id,
            um_domain: extension?.account_info?.um_domain,
            country: extension?.account_info?.country,
            name: extension?.name,
            calls: extension?.calls,
        };
    }, [extension]);
};

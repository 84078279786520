import { makeStyles } from '@material-ui/core/styles';
import { BaseWallboardWidgetPositionProps, BaseWallboardWidgetSizeProps, BaseWallboardWidgetViewProps } from '../../store/types/Wallboard';
import { Colors } from '../../styles/Colors';

export const defaultColumnsAmount = 8;

export const useStyles = makeStyles(() => ({
    container: {
        height: '100vh',
        overflowX: 'hidden',
        overflowY: 'auto',
        flex: 1,
        background: Colors.White
    },
    scrollableContainer: {
        display: 'flex',
        minHeight: '100vh',
        flex: 1,
        backgroundColor: Colors.White
    },
    containerPadding: {
        background: Colors.White,
        padding: '38px 0px 0px 0px',
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 32px)',
        position: 'relative'
    },
    headerContainer: {
        paddingLeft: 27,
        width: 'calc(100% - 32px)',
        height: 52,
        display: 'flex'
    },
    mainHeader: {
        fontSize: 32,
        color: Colors.Gray7,
        fontWeight: 500,
    },
    doughnutsContainer: {
        width: '100%',
        position: 'absolute',
        top: 98,
        minHeight: 'calc(100vh - 98px)',

        '& .react-grid-layout': {
            background: Colors.White
        },

        '& .react-grid-item.react-grid-placeholder': {
            backgroundColor: Colors.Secondary5,
            border: '1px dashed ' + Colors.Secondary2,
            borderRadius: 4,
            zIndex: 3,
        }
    },
    react_grid_layout: {
        position: 'relative',
        transition: 'height 200ms ease',
    },
    reactGridItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 200ms ease',
        transitionProperty: 'left, top',
        background: Colors.White,
        fontFamily: 'Arial, Helvetica, sans-serif',
        textAlign: 'center',
        border: '1px solid ' + Colors.Border,
        borderRadius: 4,
        zIndex: 5,
        
        '& .MuiButtonBase-root': {
            visibility: 'hidden'
        },
    },
    resizeHandle: {
        display: 'flex',
        position: 'absolute',
        width: 22,
        height: 22,
        right: 0,
        bottom: 0,
        background: 'transparent',
        color: 'transparent',
        cursor: 'pointer',
        zIndex: 9,
        visibility: 'hidden',
        
        '& svg': {
            width: 12,
            height: 12,

            '& path': {
                fill: `${Colors.Gray5} !important`,
                fillOpacity: 1,
            },
        },
    },
    deleteButton: {
        position: 'absolute',
        top: 8,
        right: 5.5,
        width: 40,
        height: 40,
        fontSize: 18,
        zIndex: 8,
        background: Colors.White,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',

        '&:hover': {
            background: `${Colors.White} !important`,
        },
        
        '& .MuiIconButton-root': {
            '&:hover': {
                background: `${Colors.White} !important`,
            },
        },
        
        '&:disabled': {
            background: `${Colors.White} !important`,
        },
    },
    editButton: {
        position: 'absolute',
        top: 8,
        right: 49.5,
        width: 40,
        height: 40,
        fontSize: 18,
        zIndex: 8,
        background: Colors.White,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
        
        '&:hover': {
            background: `${Colors.White} !important`,
        },
        
        '& .MuiIconButton-root': {
            '&:hover': {
                background: `${Colors.White} !important`,
            },
        },
        
        '&:disabled': {
            background: `${Colors.White} !important`,
        },
    },
    widgetTypeIcon: {
        position: 'absolute',
        top: 20,
        left: 18,
    },
    widgetTitle: {
        position: 'absolute',
        top: 18,
        left: 44,
        color: Colors.Secondary,
        maxWidth: 'calc(100% - 44px - 10px)',
        width: 'fit-content',
        fontWeight: 700,
        fontSize: 18,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    widgetFrame: {
        width: 'calc(100% - 32px)',
        height: 'calc(100% - 56px - 8px)',
        zIndex: 7,
        padding: '8px 16px 0px 16px',
        position: 'absolute',
        top: 56,
        left: 0
    },
    fullWidthContainer: {
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0
    },
    emptyRightMarginContainer: {
        width: 'calc(100% - 16px)',
        paddingRight: 0
    },
    addWidgetButton: {
        marginTop: -8,
        marginLeft: 36,
        color: Colors.White
    },
    plusIcon: {
        '& svg.MuiSvgIcon-root path': {
            fill: Colors.White + '!important',
            fillOpacity: 1,
        },
    },
    refreshButton: {
        width: 40,
        height: 40,
        marginLeft: 16,
        marginTop: -8
    },
    refreshTimeContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        color: Colors.Text,
        flexDirection: 'column',
        width: 'fit-content',
        lineHeight: 1.2,
        marginTop: 3,
        marginLeft: 12
    },
    timeText: {
        fontStyle: 'italic',
        marginLeft: 10,
    },
    noWrap: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    selectedWidget: {
        border: '1px solid ' + Colors.Secondary12,
        boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.15)',

        '& .MuiButtonBase-root': {
            visibility: 'visible'
        }
    },
    selectedResizer: {
        visibility: 'visible'
    },
    moveButton: {
        position: 'absolute',
        top: 10,
        left: '50%',
        width: 16,
        height: 4,
        fontSize: 18,
        zIndex: 10,
        background: 'transparent',
        cursor: 'pointer'
    },
    deleteAlert: {
        '& .MuiDialogContent-root': {
            width: 233,
            minWidth: 'unset',
            padding: 24,
            paddingBottom: 27,
            background: Colors.White,
        },
    },
}));

export const calculateFreeSpace = (need: BaseWallboardWidgetSizeProps, exists: BaseWallboardWidgetViewProps[]) => {

    const isCellBusy = (px: number, py: number) => {
        for(const el of exists) {
            const busyX = px >= el.x && px < (el.x + el.w);
            const busyY = py >= el.y && py < (el.y + el.h);
            if(busyX && busyY) 
                return true;
        }
        return false;
    }

    for (let y=0; y < 1000; y++) {
        for (let x=0; x <= defaultColumnsAmount - need.w; x++) {
            
            let isPossible = true;
            for (let dx=0; dx < need.w; dx++) {
                for (let dy=0; dy < need.h; dy++) {
                    const isBusy = isCellBusy(x + dx, y + dy);
                    if(isBusy) {
                        isPossible = false;
                        break;
                    }
                }

                if(!isPossible) {
                    break;
                }
            }

            if(isPossible) {
                return {
                    x: x,
                    y: y
                } as BaseWallboardWidgetPositionProps;
            }
        }
    }
}
import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    widgetMainContainer: {
        width: '100%',
        height: '100%',
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        height: '100%',
        overflow: 'hidden'
    },
    chartMainContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    chartContainer: {
        position: 'relative',
        width: '100%',
    },
    chartContainerEmptyData: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center'
    },
    chart: {
        position: 'absolute',
        top: 0,
    },
    chartPlaceholder: {
        height: 70,
        borderRadius: '50%',
        border: `transparent solid 20px`,
    },
    chartEmptyBackground: {
        borderColor: '#D9D9D9',
        aspectRatio: '1 !important',
    },
    chartLoadingBackground: {
        borderColor: 'transparent',
    },
    sumValue: {
        width: '100%',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        top: 'calc(50% - 15px)',
    },
    sumText: {
        fontSize: 26,
        zIndex: -1
    },
    loadingLabel: {
        backgroundColor: 'transparent',
        width: '100%',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        top: '50%',
        color: Colors.Gray5,
        size: 14,
        fontWeight: 400,
    },
    legendContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        width: 'calc(100% - 16px)',
        justifyContent: 'center',
        minHeight: 82,
    },
    legendRow: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'center'
    },
    legendSubRow: {
        display: 'flex', 
        flexDirection: 'column', 
        width: 'fit-content', 
        justifyContent: 'flex-start'
    },
    secondColumn: {
        marginLeft: 32
    },
    lastItem: {
        '& > :first-child': {
            marginTop: 0,
        }
    },
    overflowStyleForListWidgetType: {
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    listViewMainContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '100%',
        height: '100%',
    },
    noDataContainer: {
        width: 'calc(100% - 40px)',
        height: 'calc(100% - 40px)',
    },
    listViewItem: {
        display: 'flex',
        flexDirection: 'row',
        width: 293,
        height: 57,
        marginLeft: 24
    },
    nativeSelectContainer: {
        width: '100%',
        height: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    nativeSelect: {
        position: 'absolute',
        top: 0,
        background: 'transparent !important',
        maxWidth: 'calc(100% - 16px)',

        '& .MuiInput-underline:before': {
            transition: 'unset !important'
        },

        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 'none !important'
        },

        '& .MuiNativeSelect-select:focus': {
            backgroundColor: 'transparent !important'
        },

        '& .MuiInputBase-input': {
            border: 'none !important'
        },

        '& .MuiSelect-select:focus': {
            background: 'transparent !important'
        },

        '& .MuiSelect-root': {
            fontWeight: 400,
            fontSize: 12,
            lineHeight: '16px',
            color: Colors.Gray5,
            opacity: 0.8
        }
    },
    nativeSelectOption: {
        height: 32,
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: Colors.Text,
        padding: '8px !important',
        width: 152,

        '&:hover': {
            backgroundColor: Colors.Secondary4
        }
    },
    listViewHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 'calc(100% - 16px)',
        height: 12.5,
        alignItems: 'center'
    },
    listViewHeaderItem: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '16px',
        color: Colors.Gray5,
        opacity: 0.8
    },
    nativeSelectInList: {
        position: 'relative',
        top: 'unset',
        right: 'unset'
    },
    listViewItemsContainer: {
        width: '100%',
        height: 'calc(100% - 13px)',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
}));
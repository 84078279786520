import {
    CallProcessingModeInfo,
    CustomerExtension,
    ExtensionType,
} from '../../../types/Extension';
import {ServiceFeature} from '../../../types/ServiceFeature';
import {CustomerInfo} from '../../../types/CustomerInfo';
import {ActionType, createReducer} from 'typesafe-actions';
import {actions} from '../../..';
import {CustomerDIDNumberType} from '../../../types/CustomerDIDNumber';
import {APIErrorInterface} from '../../../types';
import {FetchDataStatus} from '../../autoAttendants/reducer';
import {Service} from '../../../../components/Extensions/ServiceIcon';
import {AssignedRingGroupsReducerState} from '../assignedRingGroups/reducer';
import {StatusFilterType} from '../../../sagas/extensions/extensions/saga';
import {DialingRuleInfo} from '../../../types/DialingRule';
import {OperationMode} from '../../../actions/payloads';
import {ChangeForwardingModeState} from '../../../types/CallScreening';
import {BillStatus} from "../../../types/SipTrunk";

export interface ExtensionsListItem {
    id: string;
    account_id: string;
    i_account: number;
    account_info?: ExtensionType;
    numbers?: string[];
    i_prompt?: number | null;
    i_c_ext?: number;
    i_customer?: number;
    name?: string;
    ip_phone_name?: string;
    didNumbers?: string[];
    serviceFeatures?: ServiceFeature[];
    fetchDIDNumbers?: FetchDataStatus;
    fetchSipStatus?: FetchDataStatus;
    callProcessingModeInfo?: CallProcessingModeInfo;
    mainService?: Service;
    primary_i_c_group?: number;
}

export interface CallsItem {
    name: string,
    value: number,
    translation: string
}

export interface ActiveCall{
    name:string,
    count:number
}

export interface SipTrunksListItem extends ExtensionsListItem {
    calls: CallsItem[],
    activeCalls:ActiveCall[]
}

export interface SelectExtensionListItem {
    label?: string;
    value?: string;
    ip_phone_name?: string;
    i_c_ext?: string;
    id: string;
    account_id: number;
    i_ua?: number;
}

export type ExtensionFilters = {
    name?: string;
    extension?: string;
    email?: string;
    status?: StatusFilterType;
    didNumber?: string;
    id?: string;
    did_number?: string,
    sip_status?: number | undefined | null,
    blocked?: string
    bill_status?: BillStatus
};

export type ExtensionsState = {
    extensionList: {
        total: number;
        items: ExtensionsListItem[];
        filters: ExtensionFilters;
        registeredTotal?: number;
        allTotal?: number;
    };

    extensionDetails?: ExtensionsListItem;

    customerExtensionDetails?: CustomerExtension;
    customerInfoDetails?: CustomerInfo & {
        didNumbers: CustomerDIDNumberType[];
    };
    dialingRule?: DialingRuleInfo;

    errors?: APIErrorInterface;
    createNewExtensionErrors?: APIErrorInterface;

    isLoading: boolean;
    isFormSending?: boolean;
    isExtensionDeleted?: boolean;
    createExtensionDataIsLoading?: boolean;
    isCreatingNewExtension?: boolean;

    isSwitchModeDataLoading?: boolean;
    isSwitchModeFormSaving?: boolean;
    callProcessingOperationModeList?: OperationMode[];

    isCreatingPolicyMode?: boolean;
    isEditingPolicyMode?: boolean;

    fetchDialingRulesApiError?: APIErrorInterface;

    changeForwardingModeState: ChangeForwardingModeState;
} & AssignedRingGroupsReducerState;

export const initialState: ExtensionsState = {
    extensionList: {
        total: 0,
        items: [],
        filters: {},
    },
    isLoading: false,
    isExtensionDeleted: false,
    changeForwardingModeState: {stateValue: []}
};

export type ExtensionsActions = ActionType<typeof actions>;

export const extensionsReducer = createReducer<ExtensionsState,
    ExtensionsActions>(initialState)
    .handleAction(actions.deleteCustomerExtension.request, (state) => ({
        ...state,
        isLoading: true,
    }))
    .handleAction(actions.deleteCustomerExtension.success, (state, action) => {
        const items = state.extensionList.items.filter(
            (o) => o.id !== action.payload.id,
        );
        return {
            ...state,
            extensionList: {
                ...state.extensionList,
                items: items,
                total: items.length,
                registeredTotal: state.extensionList.registeredTotal,
            },
            isLoading: false,
        };
    })
    .handleAction(actions.deleteCustomerExtension.failure, (state) => ({
        ...state,
        isLoading: false,
    }))
    .handleAction(
        actions.deleteCustomerExtensionDetailsView.request,
        (state) => ({
            ...state,
            isFormSending: true,
            isExtensionDeleted: false,
        }),
    )
    .handleAction(
        actions.deleteCustomerExtensionDetailsView.success,
        (state) => ({
            ...state,
            isFormSending: false,
            isExtensionDeleted: true,
        }),
    )
    .handleAction(
        actions.deleteCustomerExtensionDetailsView.failure,
        (state) => ({
            ...state,
            isFormSending: false,
            isExtensionDeleted: false,
        }),
    )
    .handleAction(actions.getExtensionsList.request, (state, action) => ({
        ...state,
        apiError: undefined,
        extensionList: {
            ...state.extensionList,
            filters: {
                name: action.payload.nameFilter,
                extension: action.payload.extensionFilter,
                didNumber: action.payload.didNumberFilter,
                email: action.payload.emailFilter,
                status: action.payload.statusFilter,
            },
            items: action.payload.withReset ? [] : state.extensionList.items,
        },
        isExtensionDeleted: false,
        isLoading: true,
    }))
    .handleAction(actions.getExtensionsList.success, (state, action) => ({
        ...state,
        extensionList: {
            ...state.extensionList,
            ...action.payload,
            allTotal: action.payload.total || state.extensionList.allTotal || 0 ,
        },
        isLoading: false,
    }))
    .handleAction(actions.getExtensionsList.failure, (state, action) => ({
        ...state,
        errors: action.payload,
        isLoading: false,
    }))
    .handleAction(
        actions.getDIDNumbersForExtension.success,
        (state, action) => ({
            ...state,
            extensionList: {
                ...state.extensionList,
                items:
                    state.extensionList.items.map((o) => {
                        if (o.i_account == action.payload.i_account) {
                            return {
                                ...o,
                                numbers: o.numbers && [
                                    ...new Set([
                                        ...o.numbers,
                                        ...action.payload.numbers.map(
                                            (n) => n.did_number,
                                        ),
                                    ]),
                                ],
                                fetchDIDNumbers: FetchDataStatus.Done,
                            };
                        }
                        return o;
                    }) || [],
            },
        }),
    )
    .handleAction(
        actions.getDIDNumbersForExtension.failure,
        (state, action) => ({
            ...state,
            extensionList: {
                ...state.extensionList,
                items: state.extensionList.items.map((o) => {
                    if (o.i_account === action.payload.i_account) {
                        return {
                            ...o,
                            fetchDIDNumbers: FetchDataStatus.Done,
                        };
                    }

                    return o;
                }),
            },
        }),
    )
    .handleAction(actions.getAllExtensionsList.request, (state, action) => ({
        ...state,
        extensionList: {
            ...state.extensionList,
            filters: {
                name: action.payload.nameFilter,
                extension: action.payload.extensionFilter,
                didNumber: action.payload.didNumberFilter,
                email: action.payload.emailFilter,
                status: action.payload.statusFilter,
            },
        },
        isExtensionDeleted: false,
        isLoading: true,
    }))
    .handleAction(actions.getAllExtensionsList.success, (state, action) => ({
        ...state,
        extensionList: {
            ...state.extensionList,
            ...action.payload,
        },
        isLoading: false,
    }))
    .handleAction(actions.getAllExtensionsList.failure, (state, action) => ({
        ...state,
        errors: action.payload,
        isLoading: false,
    }))
    .handleAction(actions.resetExtensionBasicDetails, (state) => ({
        ...state,
        extensionDetails: undefined,
    }))
    .handleAction(
        actions.getCustomerExtensionDetails.success,
        (state, action) => ({
            ...state,
            customerExtensionDetails: {...action.payload},
        }),
    )
    .handleAction(actions.getCustomerInfoDetails.success, (state, action) => ({
        ...state,
        customerInfoDetails: {...action.payload},
    }))
    // @ts-ignore
    .handleAction(actions.setExtensionStatus.success, (state, action) => {
        const newData = {
            ...state.extensionDetails,
            account_info: {
                ...state.extensionDetails?.account_info,
                ...action.payload.account_info,
            },
        };

        return {
            ...state,
            isLoading: false,
            extensionDetails: newData,
        };
    })
    .handleAction(actions.editExtension.request, (state) => {
        return {
            ...state,
            isFormSending: true,
        };
    })
    .handleAction(actions.editExtension.failure, (state, action) => {
        return {
            ...state,
            errors: action.payload,
            isFormSending: false,
        };
    })
    .handleAction(actions.getExtensionDetailsData.request, (state) => {
        return {
            ...state,
            device: undefined,
        };
    })
    .handleAction(actions.getExtensionDetailsData.success, (state) => {
        return {
            ...state,
        };
    })
    .handleAction(
        actions.getExtensionBasicDetailsData.success,
        (state, action) => {
            return {
                ...state,
                isFormDataLoading: false,
                extensionDetails: action.payload,
            };
        },
    )
    .handleAction(actions.refreshExtensionStatus.request, (state, action) => {
        const extensionsList = [...state.extensionList.items];
        const elementToChange = extensionsList.find(
            (v) => v.i_account === action.payload.i_account,
        );

        if (elementToChange) {
            elementToChange.fetchSipStatus = FetchDataStatus.InProgress;
        }

        return {
            ...state,
            extensionList: {...state.extensionList, items: extensionsList},
        };
    })
    .handleAction(actions.refreshExtensionStatus.success, (state, action) => {
        const extensionsList = [...state.extensionList.items];
        const elementToChange = extensionsList.find(
            (v) => v.i_account === action.payload.i_account,
        );

        if (elementToChange) {
            elementToChange.account_info = action.payload.account_info;
            elementToChange.fetchSipStatus = FetchDataStatus.Done;
        }

        return {
            ...state,
            extensionList: {...state.extensionList, items: extensionsList},
        };
    })
    .handleAction(actions.getExtensionItemDetails.success, (state, action) => ({
        ...state,
        extensionList: {
            items: [action.payload],
            total: 1,
            filters: {},
        },
    }))
    .handleAction(actions.createExtensionDetailsData.request, (state) => ({
        ...state,
        createExtensionDataIsLoading: true,
    }))
    .handleAction(actions.createExtensionDetailsData.success, (state) => ({
        ...state,
        createExtensionDataIsLoading: false,
    }))
    .handleAction(actions.createNewExtension.request, (state) => ({
        ...state,
        isCreatingNewExtension: true,
    }))
    .handleAction(actions.createNewExtension.success, (state) => ({
        ...state,
        isCreatingNewExtension: false,
    }))
    .handleAction(actions.createNewExtension.failure, (state, action) => ({
        ...state,
        isCreatingNewExtension: false,
        createNewExtensionErrors: action.payload,
    }))
    .handleAction(actions.clearErrorsCreateNewExtensionForm, (state) => {
        return {
            ...state,
            createNewExtensionErrors: undefined,
        };
    })
    .handleAction(
        actions.getExtensionDialingRules.success,
        (state, action) => ({
            ...state,
            dialingRule: action.payload,
        }),
    )
    .handleAction(actions.getExtensionDialingRules.failure, (state, action) => ({
        ...state,
        fetchDialingRulesApiError: action.payload
    }))
    .handleAction(actions.fetchDataForSwitchMode.request, (state) => ({
        ...state,
        isSwitchModeDataLoading: true,
    }))
    .handleAction(actions.fetchDataForSwitchMode.success, (state) => ({
        ...state,
        isSwitchModeDataLoading: false,
    }))
    .handleAction(actions.fetchDataForSwitchMode.failure, (state) => ({
        ...state,
        isSwitchModeDataLoading: false,
    }))
    .handleAction(
        actions.getCallProcessingOperationModeList.success,
        (state, action) => ({
            ...state,
            callProcessingOperationModeList: action.payload,
        }),
    )
    .handleAction(
        actions.updateAccountsCallProcessingMode.request,
        (state) => ({
            ...state,
            isSwitchModeFormSaving: true,
        }),
    )
    .handleAction(
        actions.updateAccountsCallProcessingMode.success,
        (state) => ({
            ...state,
            isSwitchModeFormSaving: false,
        }),
    )
    .handleAction(
        actions.updateAccountsCallProcessingMode.failure,
        (state) => ({
            ...state,
            isSwitchModeFormSaving: false,
        }),
    )
    .handleAction(actions.addMode.request, (state) => ({
        ...state,
        isCreatingPolicyMode: true
    }))
    .handleAction(actions.addMode.success, (state, action) => ({
        ...state,
        callProcessingOperationModeList: action.payload,
        isCreatingPolicyMode: false
    }))
    .handleAction(actions.addMode.failure, (state) => ({
        ...state,
        isCreatingPolicyMode: false
    }))
    .handleAction(actions.editMode.request, (state) => ({
        ...state,
        isEditingPolicyMode: true
    }))
    .handleAction(actions.editMode.success, (state, action) => ({
        ...state,
        callProcessingOperationModeList: action.payload,
        isEditingPolicyMode: false
    }))
    .handleAction(actions.editMode.failure, (state) => ({
        ...state,
        isEditingPolicyMode: false
    }))
    .handleAction(actions.deleteMode.success, (state, action) => ({
        ...state,
        callProcessingOperationModeList: action.payload
    }))
    .handleAction(actions.updateChangeForwardingModeState.success, (state, action) => ({
        ...state,
        changeForwardingModeState: {
            stateValue: action.payload?.stateValue
        }
    }));

export default extensionsReducer;
